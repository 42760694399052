//Node Modules
import {
  useState, memo
} from 'react';
import { nanoid } from 'nanoid';

//Components
import NavImg from './sub-components/NavImage';
import NavItem from './sub-components/NavItem';
import MobileMenuTrigger from './sub-components/MobileMenuTrigger';
import SocialNetwork from '@/components/global-components/SocialNetwork';

//Themes
import theme from '@/themes/index';

const propTypes = {};

const Navbar = ({
  content,
  isDesktop,
  navOptions,
  noNav,
}) => {
  const [activeMobileNav, setActiveMobileNav] = useState(false);
  const toggleNav = () => {
    document.querySelector('body').classList.toggle('nav-open');
    setActiveMobileNav(!activeMobileNav);
  };

  const hasNavigation = noNav ? ' no-navigation' : '';
  const socialNetworks = [
    {
      'socialNetwork' : {
        url: content.instagramUrl.url,
        icon: 'instagram-square',
      },
    },
    {
      'socialNetwork' : {
        url: content.facebookUrl.url,
        icon: 'facebook-square',
      },
    },
    {
      'socialNetwork' : {
        url: content.linkedinUrl.url,
        icon: 'linkedin',
      },
    },
    {
      'socialNetwork' : {
        url: content.youtubeUrl.url,
        icon: 'youtube-square',
      },
    },
  ];
  return (
    <>
      <ul className={`navbar ${hasNavigation} ${navOptions}`}>
        <NavImg imgPath={content.cpiLogo.url} alt={'CPI'} />
        <li className={`main-navbar-items ${activeMobileNav ? 'active' : ''}`}>
          <nav className='nav-children-flex-wrapper'>
            {content.mainLinks.map(item => (<NavItem
              key={nanoid()}
              text={item.linkLabel}
              href={item.linkUrl?.url}
            />)
            )}           
            <SocialNetwork icons={socialNetworks} />
          </nav>
        </li>
        {!isDesktop && (
          <MobileMenuTrigger active={activeMobileNav} toggleNav={toggleNav} />
        )}
      </ul>
      <style global jsx>
        {`
          .navbar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            width: 100%;
            margin: 0;
            box-shadow: unset;
            background: white;
            z-index: 200;
            height: 3.75rem;
            list-style-type: none;
            padding: 0 1rem;
          }

          .main-navbar-items {
            align-items: center;
            background: ${theme.fontColors.neutral};
            display: block;
            left: 0;
            height: 0;
            position: absolute;
            top: 3.75rem;
            transition: all 0.25s;
            overflow: hidden;
            width: 100vw;
            z-index: 100;
            transform: translateX(150vw);
          }

          .main-navbar-items .nav-children-flex-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          }

          .main-navbar-items.active {
            overflow-y: scroll;
            overflow: -moz-scrollbars-none;
            -ms-overflow-style: none;
            height: 13.75rem;
            transform: translateX(0);
          }

          .main-navbar-items.active::-webkit-scrollbar {
            display: none;
            width: 0;
          }

          @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
            .navbar {
              padding: 1rem 2.1875rem;
            }
          }

          @media only screen and (min-width: 835px) {
            .nav-children-flex-wrapper {
              width: 95%;
            }
          }

          @media only screen and (min-width: 1050px) {
            .nav-children-flex-wrapper {
              width: 100%;
            }
          }

          @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
            ul { 
              flex-direction: row;
              max-width: 67.9375rem;
              min-width: 52,1875rem;
            }

            .navbar {
              justify-content: space-between;
              position: relative;
              font-size: 0.875rem;
              background-color: transparent;
              width: 100%;
              height: 100%;
              padding: 1rem;
              margin: 0 auto;
            }

            .main-navbar-items .navbar-main-item,
            .main-navbar-items
              div.navbar-item-with-children
              > .navbar-main-item {
              color: ${theme.fontColors.darkGray};
            }

            .navbar-home
              > .main-navbar-items
              div.navbar-item-with-children
              > .navbar-main-item {
              color: ${theme.fontColors.white};
            }

            .main-navbar-items {
              background-color: transparent;
              display: flex;
              flex-direction: row;
              height: auto;
              margin: 0;
              min-height: auto;
              opacity: 1;
              transform: translateX(0);
              padding: 0;
              position: static;
              min-width: min-content;
              width: 100%;
              overflow: visible;
              justify-content: flex-end;
            }

            .main-navbar-items .nav-children-flex-wrapper {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              white-space: nowrap;
              min-width: min-content;
            }

            .main-navbar-items .navbar-main-item {
              font-size: 0.875rem;
              background-color: transparent;
              width: auto;
              text-align: inherit;
              padding: 0;
              border-radius: 0;
            }

            .main-navbar-items.active {
              overflow: visible;
              min-height: auto;
              height: auto;
              padding: 1rem;
            }
          }

          @media only screen and (max-width: ${theme.breakpoints.desktopMax}) {
            .navbar {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

Navbar.propTypes = propTypes;

export default memo(Navbar);
