//Node Modules
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

//Themes
import theme from '@/themes/index';

const propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
};

const isItemActive = url => {
  const router = useRouter();

  const urlWithNoQueryStrings =
     url.indexOf('?') > 0 ? url.substring(1, url.indexOf('?')) : url;
  const splittedUrl = urlWithNoQueryStrings.split('/');
  const currentPath = splittedUrl.reverse()[0];
  return router.pathname.substring(1) === currentPath ? 'active' : '';
};

const NavItem = ({
  href = '',
  text = '', 
}) => (
  <>
    <a
      className={`navbar-main-item ${isItemActive(href)}`}
      data-qa-header-link={text}
      href={href}
      target={'_self'}
      rel='noopener noreferrer'
    >
      {text}
    </a>
    <style jsx>{`
      .navbar-main-item {
        padding: 8px 15px;
        background-color: #fff;
        color: ${theme.fontColors.optional5};
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        color: ${theme.fontColors.secondary};
        font-family: ${theme.fontFamilies.montserrat};
      }

      .navbar-main-item.active {
        font-family: ${theme.fontFamilies.montserratBold};
      }
    `}</style>
  </>
);

NavItem.propTypes = propTypes;

export default NavItem;
